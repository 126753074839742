import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import { saveAs } from "file-saver";
import axios from "axios";
// import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "NewExPage",
  data() {
    let ss = new MainService();
    return {
      msg: "NewExPage",
      ss: ss,
      ajax: {
        url: ss.indexNewEx(),
        headers: ss.getToken(),
      },
      newexs: [],
      newex: {},
      isLoading: false,
      errorBag: {},
      carreras: [],
      fechaInicio: null,
      fechaFin: null,
      selectedCarrera1: null,
      selectedSemestre1: null,
      selectedPeriodo1: null,
      selectedCarrera2: null,
      selectedSemestre2: null,
      selectedPeriodo2: null,
      fechasValidas: false,
      certificadosValidos: false,
      noCertificadosValidos: false,
    };
  },
  methods: {
    validarFechas() {
      this.fechasValidas = !!this.fechaInicio && !!this.fechaFin;
    },
    validarCertificados() {
      if (this.persona.Rol > 3) {
        // Si el rol es mayor a 3, no se requiere que la carrera esté seleccionada
        this.certificadosValidos =
          !!this.selectedSemestre1 &&
          !!this.selectedPeriodo1;
      } else {
        // Si el rol es menor o igual a 3, se requiere que la carrera esté seleccionada
        this.certificadosValidos =
          !!this.selectedCarrera1 &&
          !!this.selectedSemestre1 &&
          !!this.selectedPeriodo1;
      }
    },
    validarNoCertificados() {
      if (this.persona.Rol > 3) {
        // Si el rol es mayor a 3, no se requiere que la carrera esté seleccionada
        this.noCertificadosValidos =
          !!this.selectedSemestre2 &&
          !!this.selectedPeriodo2;
      } else {
        // Si el rol es menor o igual a 3, se requiere que la carrera esté seleccionada
        this.noCertificadosValidos =
          !!this.selectedCarrera2 &&
          !!this.selectedSemestre2 &&
          !!this.selectedPeriodo2;
      }
    },
    
    CargarNewEx() {
      this.ss
        .listNewEx()
        .then((result) => {
          this.carreras = result.data.data; // almacena los datos de las carreras
          console.log(this.carreras); // imprime los datos de las carreras para verificar
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withNewEx(selectedCarrera1, selectedSemestre1, selectedPeriodo1) {
      this.ss
        .conNewEx(selectedCarrera1, selectedSemestre1,selectedPeriodo1)
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "AlumnosCertificados.xlsx");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withinNewEx(selectedCarrera2, selectedSemestre2, selectedPeriodo2) {
      this.ss
        .sinNewEx(selectedCarrera2, selectedSemestre2, selectedPeriodo2)
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "AlumnosSinCertificados.xlsx");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withPdfNewEx(selectedCarrera1, selectedSemestre1, selectedPeriodo1) {
      this.ss
        .conPdfNew(selectedCarrera1, selectedSemestre1,selectedPeriodo1)
        .then((response) => {
          //console.log("URL del PDF:", response.data.pdf_url);
          window.open(response.data.pdf_url);
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast("Seleccione una Carrera", {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    withinPdfNewEx(selectedCarrera2, selectedSemestre2, selectedPeriodo2) {
      this.ss
        .sinPdfNew(selectedCarrera2, selectedSemestre2, selectedPeriodo2)
        .then((response) => {
          //console.log("URL del PDF:", response.data.pdf_url);
          window.open(response.data.pdf_url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withNewExFechas(fechaInicio, fechaFin) {
      // Valida que las fechas no estén vacías
      if (!fechaInicio || !fechaFin) {
        this.$bvToast.toast("Debe seleccionar las fechas de inicio y fin", {
          title: "Error",
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }

      this.ss
        .conNewExFechas(fechaInicio, fechaFin)
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "ReportePorFechas.xlsx");
        })
        .catch((error) => {
          console.log(error);
          this.$bvToast.toast("Ocurrió un error al generar el reporte", {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    extraerdatos() {
      this.ss
        .extractCreateInst()
        .then((response) => {
          console.log("Datos:", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    dataTable,
    // Loader
  },
  computed: {
    persona() {
      return JSON.parse(localStorage.getItem("persona"));
    },
  },
  mounted() {
    this.CargarNewEx();
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
