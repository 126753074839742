import { render, staticRenderFns } from "./AlumnoPage.vue?vue&type=template&id=9bf8005a"
import script from "./AlumnoPage.js?vue&type=script&lang=js&external"
export * from "./AlumnoPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports